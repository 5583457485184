import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from "@nextui-org/react";
import { isNil } from "lodash";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../../..";
import { Asset } from "../../../../../lookups/assets";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY
} from "../../../../../utils";
import { BASE_URL } from "../../../../../utils/constants";

export type NewTokenFormType = {
  amount: number;
};

export const DirectFundComponentDialog = (props: {
  isOpen: boolean;
  customerId: string;
  closeModal: () => void;
}) => {
  const schema = yup
    .object({
      amount: yup.number().required().default(100).min(100),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<NewTokenFormType>({
    defaultValues: {
      amount: 100,
    } as any,
    resolver: yupResolver(schema as any),
  });
  const { setLoading } = useContext(LoadingContext);
  const { client: medusaClient } = useMedusa();

  const createToken = async (data: NewTokenFormType) => {
    console.error(data);
    setLoading!(true);

    // create the deposit and dispose
    fetch(`${BASE_URL}admin/customer/direct-fund`, {
      method: "POST",
      body: JSON.stringify({
        amount: data.amount,
        customerId: props.customerId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }

        // close dialog

        toast.success("Account funded successfully");

        reset();
        props.closeModal();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={props.isOpen}
        onClose={() => {props.closeModal(); reset();}}
      >
              <form onSubmit={handleSubmit(createToken)}>
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1">
              {'Fund Customer Account'}
            </ModalHeader>
            <ModalBody>
                <div className="grid grid-cols-1 gap-4 mb-4">

                  <div className="flex flex-col items-start">
                    <Input
                      type="text"
                      className="mb-4"
          startContent={<h4 className="text-sm font-bold">NGN</h4>}
                      label="Amount"
                      {...register("amount", { required: true })}
                      errorMessage={errors.amount?.message}
                      isInvalid={!isNil(errors.amount?.message)}
                      color={errors.amount?.message ? "danger" : undefined}
                    />

                  </div>

                </div>
            </ModalBody>
            <ModalFooter>
              <Button color="warning" type="submit">
                Submit
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
        </form>
      </Modal>
    </>
  );
};
