import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Select,
  SelectItem,
  User,
} from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { Customer } from "@medusajs/medusa";
import { first } from "lodash";
import { FormImage } from "../../../../../utils";
import { useMedusa } from "medusa-react";
import { useContext, useState } from "react";
import { LoadingContext } from "../../../../..";
import { toast } from "react-toastify";
import { DirectFundComponentDialog } from "./direct-fund-component";
import { DirectDebitComponentDialog } from "./direct-debit-component";

const pairs = [
  {
    key: "USD/XYZ",
    label: "USD to XYZ token",
  },
];

export const CustomerDetailHeaderComponent = (props: {
  customer: Customer;
  onClose?: () => void;
}) => {
  const { client: medusaClient } = useMedusa();
  const { setLoading } = useContext(LoadingContext);

  const [isDirectFundDialogOpen, setIsDirectFundDialogOpen] = useState(false);
  const [isDirectDebitDialogOpen, setIsDirectDebitDialogOpen] = useState(false);

  const updateUserType = (level: string) => {
    setLoading!(true);
    medusaClient.admin.customers
      .update(props.customer.id, {
        level,
      } as any)
      .then(async (response) => {
        toast.success("Level Updated Successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  const freezeUser = (shouldFreeze: boolean) => {
    setLoading!(true);
    medusaClient.admin.customers
      .update(props.customer.id, {
        is_frozen: shouldFreeze,
      } as any)
      .then(async (response) => {
        toast.success(shouldFreeze ? "Account frozen" : "Account unfrozen");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <div className="z-30 flex flex-rows justify-between pr-8 absolute top-0 border-b-2 border-slate-300 flex w-full bg-white rounded-none">
        <Button
          isIconOnly
          color="warning"
          aria-label="Back"
          radius="none"
          style={{ width: "58px", height: "58px" }}
          onClick={props.onClose}
        >
          <FeatherIcon icon="arrow-left" />
        </Button>

        <Select
          // label="Rank"
          className="px-4 mb-2 w-100"
          style={{ width: "130px" }}
          placeholder="Rank"
          defaultSelectedKeys={[(props.customer as any).level]}
          onChange={(ev) => {
            updateUserType(ev.target.value);
          }}
          selectionMode="single"
        >
          <SelectItem key={"standard"} className="capitalize">
            Standard
          </SelectItem>
          <SelectItem key={"pro"} className="capitalize">
            Pro
          </SelectItem>
          <SelectItem key={"vip"} className="capitalize">
            VIP
          </SelectItem>
          <SelectItem key={"vvip"} className="capitalize">
            VVIP
          </SelectItem>
        </Select>

        <Dropdown>
          <DropdownTrigger>
            <User
              name={`${props.customer.last_name ?? ""} ${
                props.customer.first_name ?? ""
              }`.trim()}
              description={`${(props.customer as any).level} ${
                (props.customer as any).is_frozen ? "(FROZEN)" : ""
              }`}
              avatarProps={{
                src: first(
                  (props.customer.metadata?.avatar as
                    | FormImage[]
                    | undefined) ?? []
                )?.url,
              }}
            />
          </DropdownTrigger>
          <DropdownMenu
            onAction={(key) => {
              switch (key) {
                case "freeze":
                case "unfreeze":
                  freezeUser(key == "freeze");
                  break;
                case "df":
                  setIsDirectFundDialogOpen(true);
                  break;
                case "dd":
                  setIsDirectDebitDialogOpen(true);
                  break;
              }
            }}
          >
            <DropdownItem key="freeze">Freeze Account</DropdownItem>
            <DropdownItem key="unfreeze">Unfreeze Account</DropdownItem>
            <DropdownItem key="df">Direct Fund</DropdownItem>
            <DropdownItem key="dd">Direct Debit</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <DirectFundComponentDialog
        key={`dfc_${props.customer.id}`}
        isOpen={isDirectFundDialogOpen}
        customerId={props.customer.id}
        closeModal={() => {
          setIsDirectFundDialogOpen(false);
        }}
      ></DirectFundComponentDialog>

      <DirectDebitComponentDialog
        key={`dfc_${props.customer.id}`}
        isOpen={isDirectDebitDialogOpen}
        customerId={props.customer.id}
        closeModal={() => {
          setIsDirectDebitDialogOpen(false);
        }}
      ></DirectDebitComponentDialog>
    </>
  );
};
